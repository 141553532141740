import React from 'react'
import PropTypes from 'prop-types'
import {graphql} from 'gatsby'
import Layout from '../components/Layout'
import Content, { HTMLContent } from '../components/Content'
import PreviewCompatibleImage from "../components/PreviewCompatibleImage";
import Helmet from "react-helmet";

export const AboutPageTemplate = ({ title, content, contentComponent, images }) => {
  const PageContent = contentComponent || Content

  return (
    <section className="section section--gradient">
      <div className="container content">
        <div className="columns">
          <div className="column is-10 is-offset-1">
            <div className="columns">

              <div className="column is-6">
                <h2 className="title is-size-3 has-text-weight-bold is-bold-light">
                  {title}
                </h2>
                <PageContent className="content" content={content} />
              </div>
              <div className="column is-6">

                {images.map((image, index) => {
                  return (<div style={{marginBottom: 20}}>
                    <PreviewCompatibleImage
                        imageInfo={{
                          image: image,
                          alt: title,
                        }}
                    />
                  </div>)
                })}

                {/*<PreviewCompatibleImage*/}
                {/*    imageInfo={{*/}
                {/*      image: image,*/}
                {/*      alt: title,*/}
                {/*    }}*/}
                {/*/>*/}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

AboutPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
  image: PropTypes.object
}

const AboutPage = ({ data }) => {
  const { markdownRemark: post } = data
  console.log(post);
  return (
    <Layout>
      <AboutPageTemplate
        contentComponent={HTMLContent}
        title={post.frontmatter.title}
        content={post.html}
        images={post.frontmatter.images}
        helmet={
          <Helmet titleTemplate="%s | Painting">
            <title>{`${post.frontmatter.title}`}</title>
            <meta
              name="description"
              content={`${post.frontmatter.description}`}
            />
          </Helmet>
        }
      />
    </Layout>
  )
}

AboutPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default AboutPage

export const aboutPageQuery = graphql`
  query AboutPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        images {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
        description
      }
    }
  }
`
